<template>
  <div>
    <div class="travel-group w">
      <div class="travel-group-title">驴友圈子</div>
      <div class="travel-group-list-container w">
        <div class="prev-btn" @click="imgMovePrev('travelGroupList', 'travelGroupListContainer', 'prevBtn', 'nextBtn', 406, 7)" ref="prevBtn">
          <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png" alt="">
        </div>
        <div class="next-btn" @click="imgMoveNext('travelGroupList', 'travelGroupListContainer', 'prevBtn', 'nextBtn', 406, 7)" ref="nextBtn">
          <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png" alt="">
        </div>
        <div class="travel-group-list-box" ref="travelGroupListContainer">
          <div class="travel-group-list" ref="travelGroupList">
            <div v-for="item in groupList" :key="item.id" class="travel-group-item" @click="downloadApp" :style="{visibility: item.hidden === true ? 'hidden' : ''}">
              <div class="travel-group-item-left">
                <img :src="item.photo">
              </div>
              <div class="travel-group-item-right">
                <div class="travel-group-item-right-top">
                  <div class="travel-group-name">{{item.groupName}}</div>
                  <div v-if="item.isHasRedPacket" class="travel-group-icon-left">
                    <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625815219036.png" alt="">
                  </div>
                  <div v-if="item.prizeTopic" class="travel-group-icon-right"><span>有奖</span></div>
                </div>
                <div class="travel-group-item-right-middle">{{item.signature}}</div>
                <div class="travel-group-item-right-bottom">
                  <div class="travel-group-bottom-icon"><img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625814727167.png" alt="">
                    <div>{{item.memberNum}}</div>
                  </div>
                  <div v-if="item.issueNum" class="travel-group-bottom-icon" style="margin-left: 10px;"><img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625814695939.png" alt="">
                    <div>{{item.issueNum}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div v-if="travelCasesVisible" :style="{marginTop: groupHalfHidden===true ? '0' : '88px'}" class="travel-cases w">
      <div class="travel-cases-title">旅行案例</div>
      <div class="travel-cases-container">
        <div class="prev-btn" @click="imgMovePrev('travelCasesList', 'travelCasesListContainer', 'prevBtn2', 'nextBtn2', 305, 4)" ref="prevBtn2">
          <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png" alt="">
        </div>
        <div class="next-btn" @click="imgMoveNext('travelCasesList', 'travelCasesListContainer', 'prevBtn2', 'nextBtn2', 305, 4)" ref="nextBtn2">
          <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png" alt="">
        </div>
        <div class="travel-cases-box" ref="travelCasesListContainer">
          <div class="travel-cases-list" ref="travelCasesList">
            <div v-for="item in travelCasesList" :key="item.id" class="travel-cases-item" :style="{backgroundImage:'url(' + item.picPath + ')'}" @click="jumpLatestNews(item)">
              <div class="travel-cases-item-content">
                <div class="travel-cases-item-content-top">{{item.title}}</div>
                <div class="travel-cases-item-content-bottom">{{item.content}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="storySharingVisible" class="story-sharing w">
      <div class="story-sharing-title">
        故事分享
      </div>
      <div class="story-sharing-container">
        <div class="prev-btn" @click="imgMovePrev('storySharingList', 'storySharingListContainer', 'prevBtn3', 'nextBtn3', 408, 3)" ref="prevBtn3">
          <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png" alt="">
        </div>
        <div class="next-btn" @click="imgMoveNext('storySharingList', 'storySharingListContainer', 'prevBtn3', 'nextBtn3', 408, 3)" ref="nextBtn3">
          <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png" alt="">
        </div>
        <div class="story-sharing-box" ref="storySharingListContainer">
          <div class="story-sharing-list" ref="storySharingList">
            <div v-for="item in storySharingList" :key="item.id" class="story-sharing-item">
              <div class="story-sharing-item-top" :style="{backgroundImage:'url(' + item.picPath + ')'}" @click="jumpLatestNews(item)"></div>
              <div class="story-sharing-item-bottom">
                <div class="story-sharing-item-bottom-content">{{item.title}}</div>
                <div class="story-sharing-item-bottom-date">{{item.content}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { groupList, articleList } from "@/http/api";
export default {
  data() {
    return {
      groupList: [],
      travelGroupListMoving: true,
      travelCasesListMoving: true,
      storySharingListMoving: true,
      travelCasesVisible: true,
      storySharingVisible: true,
      groupHalfHidden: false,
      travelCasesList: [],
      storySharingList: [],
    };
  },
  created() {
    this.getGroupList();
    this.getArticleList("website_case");
    this.getArticleList("website_story_sharing");
  },
  mounted() {
    // 上
    this.$refs.travelGroupList.addEventListener(
      "transitionrun",
      function () {
        this.travelGroupListMoving = false;
      }.bind(this)
    );
    this.$refs.travelGroupList.addEventListener(
      "transitionend",
      function () {
        this.travelGroupListMoving = true;
      }.bind(this)
    );
    // 中
    this.$refs.travelCasesList.addEventListener(
      "transitionrun",
      function () {
        this.travelCasesListMoving = false;
      }.bind(this)
    );
    this.$refs.travelCasesList.addEventListener(
      "transitionend",
      function () {
        this.travelCasesListMoving = true;
      }.bind(this)
    );
    // 下
    this.$refs.storySharingList.addEventListener(
      "transitionrun",
      function () {
        this.storySharingListMoving = false;
      }.bind(this)
    );
    this.$refs.storySharingList.addEventListener(
      "transitionend",
      function () {
        this.storySharingListMoving = true;
      }.bind(this)
    );
  },
  methods: {
    async getGroupList() {
      const { data } = await groupList({
        key: "user",
      });
      if (data.code === 200) {
        data.data.list.forEach((item) => {
          if (item.groupDataV2) {
            item.groupDataV2.forEach((groupData, index) => {
              this.groupList.push(...groupData);
            });
          }
        });
        let hiddenCount = 0;
        this.groupList.forEach((item) => {
          if (item.hidden) {
            hiddenCount++;
          }
        });
        if (hiddenCount === 3) {
          this.groupHalfHidden = true;
        }
      }
      this.groupList.forEach((item) => {
        item.photo = this.$oss + item.photo;
      });
      if (this.groupList.length > 6) {
        this.$refs.nextBtn.style.display = "block";
      }
    },
    async getArticleList(nid) {
      const { data } = await articleList({ nid: nid });
      if (data.code === 200) {
        let list = [];
        list = data.data.articles;
        list.forEach((item) => {
          item.picPath = this.$oss + item.picPath;
        });
        if (nid === "website_case") {
          if (list.length > 3) {
            this.$refs.nextBtn2.style.display = "block";
          }
          this.travelCasesList = list;
        } else if (nid === "website_story_sharing") {
          if (list.length > 3) {
            this.$refs.nextBtn3.style.display = "block";
          }
          this.storySharingList = list;
        }
      } else {
        if (nid === "website_case") {
          this.travelCasesVisible = false;
        } else if (nid === "website_story_sharing") {
          this.storySharingVisible = false;
        }
      }
    },
    imgMoveNext(moveBox, container, prevBtn, nextBtn, itemWidth, imgSize) {
      // imgSize: 页面中显示的张数
      let movingFlag;
      if (moveBox == "travelGroupList") {
        movingFlag = this.travelGroupListMoving;
      } else if (moveBox == "travelCasesList") {
        movingFlag = this.travelCasesListMoving;
      } else if (moveBox == "storySharingList") {
        movingFlag = this.storySharingListMoving;
      }
      if (!movingFlag) {
        return;
      }
      const pBtn = this.$refs[prevBtn];
      const nBtn = this.$refs[nextBtn];
      const imgMoveBox = this.$refs[moveBox];
      const imgContainer = this.$refs[container].offsetWidth;
      const maxLength =
        imgMoveBox.children[imgMoveBox.children.length - 1].offsetLeft;
      if (Math.abs(imgMoveBox.offsetLeft) >= maxLength - imgContainer) {
        return;
      }
      imgMoveBox.style.left = imgMoveBox.offsetLeft + -itemWidth + "px";
      // 隐藏右侧按钮

      if (
        imgMoveBox.children.length * itemWidth ===
        Math.abs(imgMoveBox.offsetLeft - itemWidth * (imgSize + 1))
      ) {
        nBtn.style.display = "none";
      } else {
        nBtn.style.display = "block";
      }
      // 隐藏左侧按钮
      if (Math.abs(imgMoveBox.offsetLeft) >= 0) {
        pBtn.style.display = "block";
      } else {
        pBtn.style.display = "none";
      }
    },

    imgMovePrev(moveBox, container, prevBtn, nextBtn, itemWidth, imgSize) {
      if (!this.travelGroupListMoving) {
        return;
      }
      const pBtn = this.$refs[prevBtn];
      const nBtn = this.$refs[nextBtn];
      const imgMoveBox = this.$refs[moveBox];
      if (Math.abs(imgMoveBox.offsetLeft) <= 0) {
        return;
      }
      imgMoveBox.style.left = imgMoveBox.offsetLeft + itemWidth + "px";
      // 隐藏右侧按钮
      if (
        imgMoveBox.children.length * itemWidth ===
        Math.abs(imgMoveBox.offsetLeft - itemWidth * imgSize + 1)
      ) {
        nBtn.style.display = "none";
      } else {
        nBtn.style.display = "block";
      }
      // 隐藏左侧按钮
      if (Math.abs(imgMoveBox.offsetLeft) <= itemWidth) {
        pBtn.style.display = "none";
      } else {
        pBtn.style.display = "block";
      }
    },
    travelGroupListMove() {
      if (!this.travelGroupListMoving) {
        return;
      }
      const travelGroupList = this.$refs.travelGroupList;
      const containerWidth = this.$refs.travelGroupListContainer.offsetWidth;
      const maxLength =
        travelGroupList.children[travelGroupList.children.length - 1]
          .offsetLeft;
      if (Math.abs(travelGroupList.offsetLeft) >= maxLength - containerWidth) {
        return;
      }
      travelGroupList.style.left = travelGroupList.offsetLeft + -406 + "px";
    },
    travelCasesListMove() {
      if (!this.travelCasesListMoving) {
        return;
      }
      const travelCasesList = this.$refs.travelCasesList;
      const containerWidth = this.$refs.travelCasesListContainer.offsetWidth;
      const maxLength =
        travelCasesList.children[travelCasesList.children.length - 1]
          .offsetLeft;
      if (Math.abs(travelCasesList.offsetLeft) >= maxLength - containerWidth) {
        return;
      }
      travelCasesList.style.left = travelCasesList.offsetLeft + -305 + "px";
    },
    storySharingListMove() {
      if (!this.storySharingListMoving) {
        return;
      }
      const storySharingList = this.$refs.storySharingList;
      const containerWidth = this.$refs.storySharingListContainer.offsetWidth;
      const maxLength =
        storySharingList.children[storySharingList.children.length - 1]
          .offsetLeft;
      if (Math.abs(storySharingList.offsetLeft) >= maxLength - containerWidth) {
        return;
      }
      storySharingList.style.left = storySharingList.offsetLeft + -408 + "px";
    },
    downloadApp() {
      this.$router.push({
        name: "appDownload",
      });
    },
    jumpLatestNews(params) {
      this.$router.push({
        name: "latestNews",
        query: params,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.travel-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 180px;
  height: 346px;
  .travel-group-title {
    font-size: 28px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #333333;
  }
  .travel-group-list-container {
    position: relative;
    .prev-btn {
      display: none;
      position: absolute;
      top: calc(50% - 34px);
      left: -34px;
      z-index: 999;
      width: 68px;
      height: 68px;
      transform: rotate(180deg);
      cursor: pointer;
    }
    .next-btn {
      display: none;
      position: absolute;
      top: calc(50% - 34px);
      right: -34px;
      z-index: 999;
      width: 68px;
      height: 68px;
      cursor: pointer;
    }
  }
  .travel-group-list-box {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 248px;
    .travel-group-list {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
      width: 200%;
      height: 248px;
      transition: all 1s;
      .travel-group-item {
        display: flex;
        margin-right: 16px;
        width: 390px;
        height: 112px;
        cursor: pointer;
        .travel-group-item-left {
          width: 112px;
          height: 112px;
          border-radius: 18px;
          img {
            border-radius: 18px;
            width: 100%;
            height: 100%;
          }
        }
        .travel-group-item-right {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          flex: 1;
          margin-left: 14px;
          .travel-group-item-right-top {
            display: flex;
            align-items: center;
            .travel-group-name {
              font-size: 22px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            .travel-group-icon-left {
              margin-left: 1px;
              width: 52px;
              height: 22px;
            }
            .travel-group-icon-right {
              position: relative;
              margin-left: 1px;
              background: url(https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625815274203.png)
                no-repeat;
              width: 60px;
              height: 22px;
              span {
                position: absolute;
                top: calc(50% - 7px);
                left: 42%;
                width: 28px;
                height: 14px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
          .travel-group-item-right-middle {
            width: 264px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .travel-group-item-right-bottom {
            display: flex;
            .travel-group-bottom-icon {
              display: flex;
              align-items: center;
              padding: 0 8px;
              height: 22px;
              background: #dbedff;
              border-radius: 13px;
              img {
                margin-right: 2px;
                width: 16px;
                height: 17px;
              }
              div {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #26a1ff;
              }
            }
          }
        }
      }
    }
  }
}

.travel-cases {
  .travel-cases-title {
    display: flex;
    justify-content: center;
    font-size: 28px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #333333;
  }
  .travel-cases-container {
    position: relative;
    margin-top: 58px;
    height: 420px;
    .prev-btn {
      display: none;
      position: absolute;
      top: calc(50% - 34px);
      left: -34px;
      z-index: 999;
      width: 68px;
      height: 68px;
      transform: rotate(180deg);
      cursor: pointer;
    }
    .next-btn {
      display: none;

      position: absolute;
      top: calc(50% - 34px);
      right: -34px;
      z-index: 999;
      width: 68px;
      height: 68px;
      cursor: pointer;
    }
    .travel-cases-box {
      overflow: hidden;
      position: relative;
      height: 100%;
      width: 100%;
      .travel-cases-list {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        width: 500%;
        height: 100%;
        transition: all 1s;
        .travel-cases-item {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin-right: 20px;
          border-radius: 18px;
          width: 285px;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          cursor: pointer;
          .travel-cases-item-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 14px;
            width: 257px;
            height: 86px;
            .travel-cases-item-content-top {
              font-size: 16px;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: #ffffff;
              //强制一行显示
              white-space: nowrap;
              overflow: hidden;
              //溢出的文字用省略号表示
              text-overflow: ellipsis;
            }
            .travel-cases-item-content-bottom {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              overflow: hidden;
              text-overflow: ellipsis;
              // 弹性伸缩盒子模型显示
              display: -webkit-box;
              // 限制在一个块元素显示的文本的行数
              -webkit-line-clamp: 3;
              // 设置或检索伸缩盒对象的子元素的排列方式
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
}
.story-sharing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 88px;
  height: 466px;
  .story-sharing-title {
    display: flex;
    justify-content: center;
    font-size: 28px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #333333;
  }
  .story-sharing-container {
    position: relative;
    height: 368px;
    .prev-btn {
      display: none;
      position: absolute;
      top: calc(50% - 34px);
      left: -34px;
      z-index: 999;
      width: 68px;
      height: 68px;
      transform: rotate(180deg);
      cursor: pointer;
    }
    .next-btn {
      display: none;
      position: absolute;
      top: calc(50% - 34px);
      right: -34px;
      z-index: 999;
      width: 68px;
      height: 68px;
      cursor: pointer;
    }
    .story-sharing-box {
      position: relative;
      overflow: hidden;
      // height: 100%;
      height: 388px;
      .story-sharing-list {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 500%;
        height: 368px;
        transition: all 1s;
        .story-sharing-item {
          margin-right: 24px;
          border-radius: 18px;
          width: 384px;
          height: 368px;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
          background: #ffffff;
          cursor: pointer;
          .story-sharing-item-top {
            border-radius: 18px 18px 0px 0px;
            height: 252px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          .story-sharing-item-bottom {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 12px 0 0 14px;
            width: 356px;
            height: 90px;
            .story-sharing-item-bottom-content {
              font-size: 22px;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              // 弹性伸缩盒子模型显示
              display: -webkit-box;
              // 限制在一个块元素显示的文本的行数
              -webkit-line-clamp: 2;
              // 设置或检索伸缩盒对象的子元素的排列方式
              -webkit-box-orient: vertical;
            }
            .story-sharing-item-bottom-date {
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              overflow: hidden;
              text-overflow: ellipsis;
              // 弹性伸缩盒子模型显示
              display: -webkit-box;
              // 限制在一个块元素显示的文本的行数
              -webkit-line-clamp: 2;
              // 设置或检索伸缩盒对象的子元素的排列方式
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
}
</style>
